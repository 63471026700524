const dev = {
  baseUrl: "http://localhost:23081",
  // baseUrl: "https://sit.tikipay.co",
  publishableKey: "xnd_public_development_WwlNl04Zf8JnKtqhJOiSMIlnY9b8OxQw2e1sx8TTqG6RMXuPTZ3YKzaoIdJh8n",
  secretKey: "aloei29dk39dkeee0329dkdle03",
  encryptKey: "afiqedsafqwefdaf",
};

const uat = {
  baseUrl: "https://sit.tikipay.co",
  publishableKey: "xnd_public_development_WwlNl04Zf8JnKtqhJOiSMIlnY9b8OxQw2e1sx8TTqG6RMXuPTZ3YKzaoIdJh8n",
  secretKey: "aloei29dk39dkeee0329dkdle03",
  encryptKey: "afiqedsafqwefdaf",
};


const uat_pg = {
  baseUrl: "https://sit.tikipay.co",
  publishableKey: "xnd_public_development_WwlNl04Zf8JnKtqhJOiSMIlnY9b8OxQw2e1sx8TTqG6RMXuPTZ3YKzaoIdJh8n",
  secretKey: "aloei29dk39dkeee0329dkdle03",
  encryptKey: "afiqedsafqwefdaf",
};


const uat_ph = {
  baseUrl: "https://sit.tikipay.co",
  publishableKey: "xnd_public_development_WwlNl04Zf8JnKtqhJOiSMIlnY9b8OxQw2e1sx8TTqG6RMXuPTZ3YKzaoIdJh8n",
  secretKey: "aloei29dk39dkeee0329dkdle03",
  encryptKey: "afiqedsafqwefdaf",
};


const uat_th = {
  baseUrl: "https://sit.tikipay.co",
  publishableKey: "xnd_public_development_WwlNl04Zf8JnKtqhJOiSMIlnY9b8OxQw2e1sx8TTqG6RMXuPTZ3YKzaoIdJh8n",
  secretKey: "aloei29dk39dkeee0329dkdle03",
  encryptKey: "afiqedsafqwefdaf",
};

const prod = {
  baseUrl: "https://api.tikipay.co",
  publishableKey: "xnd_public_production_MqolukH8w5uzt5KCkKTSf8J2DyIqiOP9PWqC6oIDwZQrwPvSAJyks5sFHXTZobJ",
  secretKey: "aloei29dk39dkeee0329dkdle03",
  encryptKey: "afiqedsafqwefdaf",
};

const prod_th = {
  baseUrl: "https://api-th.tikipay.co",
  publishableKey: "xnd_public_production_MqolukH8w5uzt5KCkKTSf8J2DyIqiOP9PWqC6oIDwZQrwPvSAJyks5sFHXTZobJ",
  secretKey: "aloei29dk39dkeee0329dkdle03",
  encryptKey: "afiqedsafqwefdaf",
};

const prod_ph = {
  baseUrl: "https://api-ph.tikipay.co",
  publishableKey: "xnd_public_production_MqolukH8w5uzt5KCkKTSf8J2DyIqiOP9PWqC6oIDwZQrwPvSAJyks5sFHXTZobJ",
  secretKey: "aloei29dk39dkeee0329dkdle03",
  encryptKey: "afiqedsafqwefdaf",
};

const prod_pg = {
  baseUrl: "https://pg.tikipay.co",
  publishableKey: "xnd_public_production_MqolukH8w5uzt5KCkKTSf8J2DyIqiOP9PWqC6oIDwZQrwPvSAJyks5sFHXTZobJ",
  secretKey: "aloei29dk39dkeee0329dkdle03",
  encryptKey: "afiqedsafqwefdaf",
};

const prod_la = {
  baseUrl: "https://api-la.tikipay.co",
  publishableKey: "xnd_public_production_MqolukH8w5uzt5KCkKTSf8J2DyIqiOP9PWqC6oIDwZQrwPvSAJyks5sFHXTZobJ",
  secretKey: "aloei29dk39dkeee0329dkdle03",
  encryptKey: "afiqedsafqwefdaf",
};

let type = {}
console.log("NODE_ENV",process.env.NODE_ENV)
console.log("VUE_APP_FLAG",process.env.VUE_APP_FLAG)
if (process.env.VUE_APP_FLAG == "development") { // 开发环境
  type = dev
} else if (process.env.VUE_APP_FLAG == "test") { // 测试环境 - 印尼
  type = uat
} else if (process.env.VUE_APP_FLAG == "test_ph") { // 测试环境 - 菲律宾
  type = uat_ph
} else if (process.env.VUE_APP_FLAG == "test_th") { // 测试环境 - 泰国
  type = uat_th
} else if (process.env.VUE_APP_FLAG == "test_pg") { // 测试环境 - 马来
  type = uat_pg
} else if (process.env.VUE_APP_FLAG == "production") { // 生产环境 - 印尼
  type = prod
} else if (process.env.VUE_APP_FLAG == "production_pg") { // 生产环境 - 马来
  type = prod_pg
} else if (process.env.VUE_APP_FLAG == "production_ph") { // 生产环境 - 菲律宾
  type = prod_ph
} else if (process.env.VUE_APP_FLAG == "production_th") { // 生产环境 - 泰国
  type = prod_th
} else if (process.env.VUE_APP_FLAG == "production_la") { // 生产环境 - 拉美
  type = prod_la
} else {
  type = prod
}
export default type
