import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
var paymentBrand;
const tikiPay = "Checkout";
const alloPay = "Checkout";
const tenaPay = "Checkout";
const localHost = location.host;
if (coverString("allo", localHost)) {
    paymentBrand = alloPay;
} else if (coverString("tena", localHost)) {
    paymentBrand = tenaPay;
} else {
    paymentBrand = tikiPay;
}

/**
 * 忽略大小写判断字符串str是否包含subStr
 * @param subStr 子字符串
 * @param str 父字符串
 * @returns boolean
 */
function coverString(subStr, str) {
    var reg = eval("/" + subStr + "/ig");
    return reg.test(str);
}

const routes = [
    {
        path: '/payResult',
        name: 'payResult',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "payResult" */ '../views/payResult/payResult.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/payCheckout',
        name: 'payCheckout',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "payCheckout" */ '../views/payCheckout/payCheckout.vue'),
        meta: {
            title: paymentBrand,
            keepAlive: true
        }
    },
    {
        path: '/newPayCheckout',
        name: 'newPayCheckout',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "newPayCheckout" */ '../views/payCheckout/newPayCheckout.vue'),
        meta: {
            title: paymentBrand,
            keepAlive: true
        }
    },
    {
        path: '/promptPay',
        name: 'promptPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/walletPay/promptPay.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/momoPay',
        name: 'momoPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/walletPay/momoPay.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/confirmPay',
        name: 'confirmPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "confirmPay" */ '../views/walletPay/iframePay.vue'),
        meta: {
            title: 'Checkout',
            keepAlive: true
        }
    },
    {
        path: '/walletPay',
        name: 'walletPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "walletPay" */ '../views/walletPay/walletPay.vue'),
        meta: {
            title: paymentBrand,
            keepAlive: true
        }
    },
    {
        path: '/newWalletPay',
        name: 'newWalletPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "newWalletPay" */ '../views/walletPay/newWalletPay.vue'),
        meta: {
            title: paymentBrand,
            keepAlive: true
        }
    },
    {
        path: '/walletSkip',
        name: 'walletSkip',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "walletSkip" */ '../views/walletSkip/walletSkip.vue'),
        meta: {
            title: paymentBrand,
            keepAlive: true
        }
    },
    {
        path: '/storePay',
        name: 'storePay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "storePay" */ '../views/storePay/storePay.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/newStorePay',
        name: 'newStorePay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "newStorePay" */ '../views/storePay/newStorePay.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/vaPay',
        name: 'vaPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/vaPay/vaPay.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/newVaPay',
        name: 'newVaPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "newVaPay" */ '../views/vaPay/newVaPay.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/cardPay',
        name: 'cardPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "cardPay" */ '../views/cardPay/cardPay.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/ph/debitPay',
        name: 'debitPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "cardPay" */ '../views/debitPay-ph/debitPay.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/id/debitPay',
        name: 'debitPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "cardPay" */ '../views/debitPay-id/debitPay.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/ph/debitChoice',
        name: 'debitChoice',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "cardPay" */ '../views/debitPay-ph/debitChoice.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/id/debitChoice',
        name: 'debitChoice',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "cardPay" */ '../views/debitPay-id/debitChoice.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/id/debitSkip',
        name: 'debitSkip',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "cardPay" */ '../views/debitPay-id/debitSkip.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/ph/debitSkip',
        name: 'debitSkip',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "cardPay" */ '../views/debitPay-ph/debitSkip.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/id/debitPayRecurring',
        name: 'debitPayRecurring',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "cardPay" */ '../views/debitPayRecurring-id/debitPayRecurring.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/id/debitChoiceRecurring',
        name: 'debitChoiceRecurring',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "cardPay" */ '../views/debitPayRecurring-id/debitChoiceRecurring.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/id/debitSkipRecurring',
        name: 'debitSkipRecurring',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "cardPay" */ '../views/debitPayRecurring-id/debitSkipRecurring.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/id/payResultRecurring',
        name: 'payResultRecurring',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "payResult" */ '../views/debitPayRecurring-id/payResultRecurring.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/dialog',
        name: 'dialog',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../components/dialog.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/qrCode',
        name: 'qrCode',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/walletPay/qrCode.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/newQrCode',
        name: 'newQrCode',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "newQrCode" */ '../views/walletPay/newQrCode.vue'),
        meta: {
            title: 'Payment Result',
            keepAlive: true
        }
    },
    {
        path: '/mock',
        name: 'mock',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/mock/mock.vue'),
        meta: {
            title: 'Mock Test Payment',
            keepAlive: true
        }
    },
    {
        path: '/link',
        name: 'link',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/payoutLink/link.vue'),
        meta: {
            title: 'link',
            keepAlive: true
        }
    },
    {
        path: '/linkBatch',
        name: 'linkBatch',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/payoutLinkBatch/link.vue'),
        meta: {
            title: 'linkBatch',
            keepAlive: true
        }
    },
    {
        path: '/404',
        name: '404',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/payoutLink/404.vue'),
        meta: {
            title: '404',
            keepAlive: true
        }
    },
    {
        path: '/overUse',
        name: 'overUse',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/payoutLinkBatch/overUse.vue'),
        meta: {
            title: 'overUse',
            keepAlive: true
        }
    },
    {
        path: '/simulator',
        name: 'simulator',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/demo/simulator.vue'),
        meta: {
            title: 'simulator',
            keepAlive: true
        }
    },
    {
        path: '/demo',
        name: 'demo',
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/demo/demo.vue'),
        meta: {
            title: 'demo',
            keepAlive: true
        }
    },
    {
        path: '/id/demo',
        name: 'demo',
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/demo/demo.vue'),
        meta: {
            title: 'demo',
            keepAlive: true
        }
    },
    {
        path: '/ph/demo',
        name: 'demo',
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/demo/demo.vue'),
        meta: {
            title: 'demo',
            keepAlive: true
        }
    },
    {
        path: '/th/demo',
        name: 'demo',
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/demo/demo.vue'),
        meta: {
            title: 'demo',
            keepAlive: true
        }
    },
    {
        path: '/my/demo',
        name: 'demo',
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/demo/demo.vue'),
        meta: {
            title: 'demo',
            keepAlive: true
        }
    },
    {
        path: '/vn/demo',
        name: 'demo',
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/demo/demo.vue'),
        meta: {
            title: 'demo',
            keepAlive: true
        }
    },
    {
        path: '/la/demo',
        name: 'demo',
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/demo/demo.vue'),
        meta: {
            title: 'demo',
            keepAlive: true
        }
    },
    {
        path: '/ep/demo',
        name: 'demo',
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/demo/ep-demo.vue'),
        meta: {
            title: 'demo',
            keepAlive: true
        }
    },
    {
        path: '/userInfoCollect',
        name: 'userInfoCollect',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/userInfoCollect/userInfoCollect.vue'),
        meta: {
            title: 'userInfoCollect',
            keepAlive: true
        }
    },
    {
        path: '/dcbPay1',
        name: 'dcbPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/dcbPay/dcbPay1.vue'),
        meta: {
            title: 'dcbPay',
            keepAlive: true
        }
    },
    {
        path: '/dcbPay2',
        name: 'dcbPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/dcbPay/dcbPay2.vue'),
        meta: {
            title: 'dcbPay',
            keepAlive: true
        }
    },
    {
        path: '/br/userInfoCollect',
        name: 'userInfoCollect',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/br/userInfoCollect.vue'),
        meta: {
            title: 'userInfoCollect',
            keepAlive: true
        }
    },
    {
        path: '/br/bankTransfer',
        name: 'bankTransfer',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/br/bankTransfer.vue'),
        meta: {
            title: 'bankTransfer',
            keepAlive: true
        }
    },
    {
        path: '/br/boleto',
        name: 'boleto',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/br/boleto.vue'),
        meta: {
            title: 'boleto',
            keepAlive: true
        }
    },
    {
        path: '/br/qrPay',
        name: 'qrPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/br/qrPay.vue'),
        meta: {
            title: 'qrPay',
            keepAlive: true
        }
    },
    {
        path: '/ph/qrPay',
        name: 'qrPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/walletPay/qrph.vue'),
        meta: {
            title: 'qrPay',
            keepAlive: true
        }
    },
    {
        path: '/mx/bankTransfer',
        name: 'bankTransfer',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/mx/bankTransfer.vue'),
        meta: {
            title: 'bankTransfer',
            keepAlive: true
        }
    },
    {
        path: '/mx/oxxo',
        name: 'invoice',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/mx/oxxo.vue'),
        meta: {
            title: 'invoice',
            keepAlive: true
        }
    },
    {
        path: '/mx/payCash',
        name: 'payCash',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/mx/payCash.vue'),
        meta: {
            title: 'payCash',
            keepAlive: true
        }
    },
    {
        path: '/mx/spei',
        name: 'spei',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/mx/spei.vue'),
        meta: {
            title: 'spei',
            keepAlive: true
        }
    },
    {
        path: '/encryptCoinPay',
        name: 'encryptCoinPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/encryptCoinPay/encryptCoinPay'),
        meta: {
            title: 'encryptCoinPay',
            keepAlive: true
        }
    },
    {
        path: '/encryptCoinPay/payResult',
        name: 'payResult',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaPay" */ '../views/encryptCoinPay/payResult.vue'),
        meta: {
            title: 'encryptCoinPay',
            keepAlive: true
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
