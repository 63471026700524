import Vue from 'vue';
import axios from 'axios';
import env from "./env";
import {isNotEmpty} from "@/assets/js/common";
import CryptoJS from "/public/static/crypto-js.4.0.0";

// import MD5 from 'crypto-js/md5';

/* eslint-disable */
let MD5 = CryptoJS.MD5;
const baseUrl = env.baseUrl
const backUrl = env.backUrl
const secretKey = env.secretKey
const publishableKey = env.publishableKey
// const baseUrl = process.env.NODE_ENV == 'production'
//   ? 'https://api.tikipay.co'
//   // ? 'https://sit.tikipay.co'
//   : 'https://sit.tikipay.co';
//   //   ? 'http://localhost:23081'
//   //   : 'http://localhost:23081';
// const secretKey = process.env.NODE_ENV == 'production'
//     ? 'aloei29dk39dkeee0329dkdle03'
//     : 'aloei29dk39dkeee0329dkdle03'
Xendit.setPublishableKey(publishableKey);
// Xendit.setPublishableKey(env.publishableKey);

const merchantId = 99999;
const version = '1.0';

export const trim = data => {
  if (data) {
    Object.keys(data).forEach(key => {
      console.log(key, 'key', typeof data[key])
      if (typeof data[key] === 'string') data[key] = data[key].trim();
    });
  }
};

Vue.prototype.$httpReqest = (url = false, paramsJson = '') => {
  if (!url) return;
  trim(paramsJson);
  const sigData = Object.assign(paramsJson, { merchantId, version });
  const param = Object.keys(sigData)
    .sort()
    .map(key => `${key}=${sigData[key]}`)
    .join('&'); // 字典排序再组合

  return new Promise((resolve, reject) => {
    axios({
      url: baseUrl + url,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      timeout: 30000,
      data: `sign=${MD5(param + secretKey
      ).toString()}&${param.replace(/encrypt=(.+?)(?=&)/, (input, $1) => {
        return `encrypt=${encodeURIComponent($1)}` || '';
      })}`
    })
      .then(res => {
        console.log('==res==', res);
        try {
          res.data.status = res.status;
          // res.data.msg = res.data.msg;
          res = res.data;

        } catch (e) {
          console.error(e);
        }
        resolve(res);
      })
      .catch(error => {
        // timeout要处理
        if (error && error.msg && /^timeout/i.test(error.msg)) {
          resolve({ status: 'BC500', walletStatusMsg: 'timeout' });
        } else reject(error);
      });
  });
};

/**
 * 重写Demo调用后台地址，加密方式
 * @param {*} url 
 * @param {*} paramsJson 
 * @returns 
 */
Vue.prototype.$httpReqestDemo = (url = false, paramsJson = '', privateKey) => {
  if (!url) return;
  trim(paramsJson);
  console.log("paramsJson", paramsJson)
  let merchantId = paramsJson.merchantId
  const sigData = Object.assign(paramsJson, { merchantId, version });
  const param = Object.keys(sigData)
    .sort()
    .filter(key => isNotEmpty(sigData[key]))
    .map(key => `${key}=${sigData[key]}`)
    .join('&'); // 字典排序再组合

  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      timeout: 30000,
      data: `sign=${MD5(param + privateKey
      ).toString()}&${param.replace(/encrypt=(.+?)(?=&)/, (input, $1) => {
        return `encrypt=${encodeURIComponent($1)}` || '';
      })}`
    })
      .then(res => {
        console.log('==res==', res);
        try {
          res.data.status = res.status;
          // res.data.msg = res.data.msg;
          res = res.data;

        } catch (e) {
          console.error(e);
        }
        resolve(res);
      })
      .catch(error => {
        // timeout要处理
        if (error && error.msg && /^timeout/i.test(error.msg)) {
          resolve({ status: 'BC500', walletStatusMsg: 'timeout' });
        } else reject(error);
      });
  });
};